import React, { useState } from "react";
import { Link } from "react-router-dom";
import blogs from "./blog.json";

const BlogList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 4;

  // Calculate the index of the last blog on the current page
  const indexOfLastBlog = currentPage * blogsPerPage;
  // Calculate the index of the first blog on the current page
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  // Get the blogs to display based on the current page
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle previous and next page changes
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate total pages
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  return (
    <div
      style={{
        fontFamily: "'Poppins', sans-serif",
        background: "linear-gradient(135deg, #4F46E5, #3B82F6)", // Gradient background
        minHeight: "100vh",
        padding: "40px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#F3F4F6",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "3rem",
          marginBottom: "30px",
          color: "#F9FAFB", // Heading color updated
          textShadow: "3px 3px 6px rgba(0, 0, 0, 0.4)", // Soft shadow for a standout effect
        }}
      >
        Explore Our Blogs
      </h1>
      <div
        style={{
          display: "grid",
          gap: "20px",
          width: "90%",
          maxWidth: "900px",
          gridTemplateColumns: "1fr", // Default: single column for mobile
        }}
      >
        {currentBlogs.map((blog, index) => (
          <div
            key={blog.id} // Use blog.id as the key instead of index
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              padding: "20px",
              borderRadius: "15px",
              boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow =
                "0 15px 25px rgba(0, 0, 0, 0.4)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.2)";
            }}
          >
            <h2
              style={{
                fontSize: "1.8rem",
                marginBottom: "10px",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
              }}
            >
              {blog.title}
            </h2>
            <p style={{ fontSize: "1rem", marginBottom: "15px" }}>
              <strong style={{ color: "rgb(220 255 0)" }}>Author:</strong>{" "}
              <span style={{ color: "#D1D5DB" }}>{blog.author}</span>
            </p>
            <p style={{ fontSize: "1rem", marginBottom: "15px" }}>
              <strong style={{ color: "rgb(220 255 0)" }}>Category:</strong>{" "}
              <span style={{ color: "#D1D5DB" }}>{blog.category}</span>
            </p>
            <p
              style={{
                color: "#E5E7EB",
                lineHeight: "1.6",
                marginBottom: "15px",
                fontSize: "1rem",
                flexGrow: 1,
              }}
            >
              {blog.content_summary}
            </p>
            <Link
              to={`/blog/${blog.id}`} // Use blog.id for the unique blog URL
              style={{
                textDecoration: "none",
              }}
            >
              <button
                style={{
                  backgroundColor: "#2563EB",
                  color: "#F3F4F6",
                  border: "none",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  transition: "background-color 0.3s ease, transform 0.2s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#1E40AF";
                  e.currentTarget.style.transform = "translateY(-3px)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#2563EB";
                  e.currentTarget.style.transform = "translateY(0)";
                }}
              >
                Read More
              </button>
            </Link>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap", // Enables wrapping for responsiveness
        }}
      >
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          style={{
            backgroundColor: "#2563EB",
            color: "#F3F4F6",
            border: "none",
            padding: "8px 15px",
            borderRadius: "5px",
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
            fontSize: "1rem",
            transition: "background-color 0.3s ease",
            minWidth: "80px", // Ensures consistent button size
          }}
        >
          Previous
        </button>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Wraps buttons on smaller screens
            gap: "5px",
            justifyContent: "center",
          }}
        >
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              style={{
                backgroundColor: "#2563EB",
                color: "#F3F4F6",
                border: "none",
                padding: "8px 15px",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "1rem",
                transition: "background-color 0.3s ease",
                minWidth: "40px", // Ensures consistent button size
              }}
              onClick={() => paginate(index + 1)}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#1E40AF";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#2563EB";
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          style={{
            backgroundColor: "#2563EB",
            color: "#F3F4F6",
            border: "none",
            padding: "8px 15px",
            borderRadius: "5px",
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
            fontSize: "1rem",
            transition: "background-color 0.3s ease",
            minWidth: "80px", // Ensures consistent button size
          }}
        >
          Next
        </button>

        {/* Current Page Indicator */}
        <div
          style={{
            color: "#fff",
            fontSize: "1rem",
            fontWeight: "bold",
            marginTop: "10px", // Adds spacing for smaller screens
            textAlign: "center",
            flexBasis: "100%", // Forces this element to take a full row
          }}
        >
          Page {currentPage} of {totalPages}
        </div>
      </div>

      <style>
        {`
          @media (min-width: 768px) {
            div[style*="grid-template-columns"] {
              grid-template-columns: repeat(2, 1fr); /* Two columns for tablet and desktop */
            }
          }
          @media (min-width: 1024px) {
            div[style*="grid-template-columns"] {
              grid-template-columns: repeat(2, 1fr); /* Two columns fixed for desktop */
            }
          }
        `}
      </style>
    </div>
  );
};

export default BlogList;
