import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Navbar from "./components/Header";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsConditions from "./pages/TermsConditions";
import AboutUs from "./pages/About";
import BlogList from "./pages/BlogList";
import BlogDetail from "./pages/BlogDetail";
import HtmlPage from "./pages/HtmlPage";
import CssPage from "./pages/CssPage";
import JsPage from "./pages/JsPage";
import ReactPage from "./pages/ReactPage";
import NotesPage from "./pages/NotesPage";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />

          {/* Notes Section */}
          <Route path="/notes" element={<NotesPage />}></Route>
          {/* Notes Page Route */}
          <Route path="/notes/html/:topic" element={<HtmlPage />} />
          <Route path="/notes/css/:topic" element={<CssPage />} />
          <Route path="/notes/js/:topic" element={<JsPage />} />
          <Route path="/notes/reactjs/:topic" element={<ReactPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
