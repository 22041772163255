import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./NotesPage.css";

function NotesPage() {
  const topics = [
    {
      name: "HTML",
      path: "/notes/html/introduction-to-html",
      color: "#FF5733",
    },
    { name: "CSS", path: "/notes/css/css-introduction", color: "#3498DB" },
    {
      name: "JavaScript",
      path: "/notes/js/javascript-introduction",
      color: "#F1C40F",
    },
    {
      name: "ReactJS",
      path: "/notes/reactjs/reactjs-introduction",
      color: "#61DBFB",
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="notes-container">
      <h1 className="page-title">Explore Web Development Notes</h1>
      <div className="cards-vertical">
        {topics.map((topic) => (
          <div key={topic.name} className="vertical-card">
            <div
              className="vertical-card-header"
              style={{ backgroundColor: topic.color }}
            ></div>
            <div className="vertical-card-content">
              <h2>{topic.name}</h2>
              <p>Discover the world of {topic.name} and elevate your skills.</p>
              <button
                className="visit-notes-button"
                onClick={() => navigate(topic.path)}
              >
                Visit Notes
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotesPage;
