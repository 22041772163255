import React from "react";

const PrivacyPolicy = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const heading1Style = {
    color: "#2c3e50",
    fontSize: "2.5em",
    marginBottom: "20px",
    textAlign: "center",
  };

  const heading2Style = {
    color: "#3498db",
    fontSize: "1.5em",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    lineHeight: "1.6",
    marginBottom: "20px",
    color: "#666",
  };

  const listStyle = {
    listStyleType: "disc",
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#666",
  };

  const contactStyle = {
    color: "#e74c3c",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <h1 style={heading1Style}>Privacy Policy </h1>
      <p style={paragraphStyle}>Effective Date: 2 December 2024</p>

      <p style={paragraphStyle}>
        At <strong>Console2Success</strong>, accessible from{" "}
        <a href="https://console2success.in" target="_blank" rel="noreferrer">
          https://console2success.in
        </a>
        , the privacy of our visitors is a top priority. This Privacy Policy
        outlines the types of information we collect, how we use it, and the
        steps we take to protect your data. By using our website, you agree to
        the terms outlined in this policy.
      </p>

      <h2 style={heading2Style}>1. Information We Collect</h2>

      <h3 style={heading2Style}>a. Personal Information</h3>
      <p style={paragraphStyle}>
        We may collect personal information such as your name, email address, or
        phone number when you voluntarily provide it through contact forms,
        sign-ups, or other interactions with our website.
      </p>

      <h3 style={heading2Style}>b. Non-Personal Information</h3>
      <p style={paragraphStyle}>
        We automatically collect certain non-personal information, including:
      </p>
      <ul style={listStyle}>
        <li>IP address</li>
        <li>Browser type</li>
        <li>Operating system</li>
        <li>Device type</li>
        <li>Referring website URLs</li>
        <li>Date and time of access</li>
      </ul>

      <h3 style={heading2Style}>c. Cookies and Tracking Technologies</h3>
      <p style={paragraphStyle}>
        We use cookies, web beacons, and other tracking technologies to enhance
        your experience. Cookies help us understand user preferences, customize
        content, and provide targeted advertisements.
      </p>

      <h2 style={heading2Style}>2. How We Use Collected Information</h2>
      <ul style={listStyle}>
        <li>To provide, operate, and maintain our website</li>
        <li>To improve, personalize, and expand our website</li>
        <li>To analyze how visitors interact with our website</li>
        <li>To display personalized advertisements through Google AdSense</li>
        <li>
          To communicate with users for customer service, updates, and marketing
          purposes
        </li>
        <li>To prevent fraudulent or malicious activities</li>
      </ul>

      <h2 style={heading2Style}>
        3. Google AdSense and Third-Party Advertising
      </h2>

      <h3 style={heading2Style}>a. Use of Google AdSense</h3>
      <p style={paragraphStyle}>
        We use Google AdSense to display ads on our website. Google, as a
        third-party vendor, uses cookies to serve ads based on your prior visits
        to our site or other websites.
      </p>
      <p style={paragraphStyle}>
        You can opt out of the use of the DART cookie by visiting the{" "}
        <a
          href="https://policies.google.com/technologies/ads"
          target="_blank"
          rel="noreferrer"
        >
          Google Ad and Content Network Privacy Policy
        </a>
        .
      </p>

      <h3 style={heading2Style}>b. Third-Party Advertising Partners</h3>
      <p style={paragraphStyle}>
        We may also use other third-party advertising services that collect
        information through cookies, web beacons, or similar technologies to
        deliver relevant ads. These third-party ad networks follow their own
        privacy policies.
      </p>

      <h2 style={heading2Style}>4. Your Privacy Choices</h2>

      <h3 style={heading2Style}>a. Managing Cookies</h3>
      <p style={paragraphStyle}>
        You can manage or disable cookies through your browser settings. Note
        that disabling cookies may affect the functionality of certain features
        on our website.
      </p>

      <h3 style={heading2Style}>b. Opting Out of Personalized Ads</h3>
      <p style={paragraphStyle}>
        You can opt out of personalized ads by visiting the following links:
      </p>
      <ul style={listStyle}>
        <li>
          <a
            href="https://adssettings.google.com"
            target="_blank"
            rel="noreferrer"
          >
            Google Ads Settings
          </a>
        </li>
        <li>
          <a
            href="https://www.networkadvertising.org/choices/"
            target="_blank"
            rel="noreferrer"
          >
            Network Advertising Initiative Opt-Out Page
          </a>
        </li>
      </ul>

      <h2 style={heading2Style}>5. Data Security</h2>
      <p style={paragraphStyle}>
        We take appropriate security measures to protect against unauthorized
        access, alteration, disclosure, or destruction of your personal
        information. However, no data transmission over the Internet or
        electronic storage is 100% secure.
      </p>

      <h2 style={heading2Style}>6. Third-Party Links</h2>
      <p style={paragraphStyle}>
        Our website may contain links to external websites. We are not
        responsible for the privacy practices or content of these third-party
        sites. We encourage you to read their privacy policies.
      </p>

      <h2 style={heading2Style}>7. Children's Privacy</h2>
      <p style={paragraphStyle}>
        Our website is not intended for children under the age of 13, and we do
        not knowingly collect personal information from children. If you believe
        we have collected information from a child under 13, please contact us
        immediately, and we will take appropriate steps to remove the data.
      </p>

      <h2 style={heading2Style}>8. Changes to This Privacy Policy</h2>
      <p style={paragraphStyle}>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. Any updates will be posted on
        this page with the revised "Effective Date."
      </p>

      <h2 style={heading2Style}>9. Contact Us</h2>
      <p style={paragraphStyle}>
        For any questions, contact us at: <br />
        <span style={contactStyle}>Email: console2success@gmail.com</span>{" "}
        <br></br>
        <span style={contactStyle}>Contact Number: 9358213928</span>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
