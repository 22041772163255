import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div
      style={{
        fontFamily: "'Poppins', sans-serif",
        backgroundColor: "#f4f8fb",
        color: "#333",
        lineHeight: "1.8",
        overflowX: "hidden",
      }}
    >
      {/* Hero Section */}
      <header
        style={{
          background: "linear-gradient(135deg, #4facfe, #00f2fe)",
          height: "60vh",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            animation: "fadeInDown 1s ease-in-out",
            textShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h1
            style={{
              fontSize: "4rem",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            Console2Success
          </h1>
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "400",
            }}
          >
            Empower Your Tech Journey with Knowledge and Growth
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            top: "-100px",
            left: "-100px",
            width: "400px",
            height: "400px",
            backgroundColor: "#ffffff22",
            borderRadius: "50%",
            filter: "blur(100px)",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: "-100px",
            right: "-100px",
            width: "400px",
            height: "400px",
            backgroundColor: "#ffffff22",
            borderRadius: "50%",
            filter: "blur(100px)",
            zIndex: 1,
          }}
        ></div>
      </header>

      {/* About Us Section */}
      <section
        style={{
          maxWidth: "1200px",
          margin: "40px auto",
          textAlign: "center",
          padding: "20px",
          background: "#ffffff",
          borderRadius: "15px",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 style={{ fontSize: "2.8rem", color: "#4facfe" }}>About Us</h2>
        <p style={{ fontSize: "1.2rem", margin: "20px 0", color: "#555" }}>
          Console2Success is a premier e-learning platform connecting learners
          to the best resources in tech. We’re dedicated to delivering
          high-quality education in an engaging way.
        </p>
      </section>

      {/* What We Offer Section */}
      <section
        style={{
          backgroundColor: "#f9f9f9",
          padding: "40px 20px",
        }}
      >
        <div
          style={{ maxWidth: "1200px", margin: "0 auto", textAlign: "center" }}
        >
          <h2 style={{ fontSize: "2.8rem", color: "#4facfe" }}>
            What We Offer
          </h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "30px",
              marginTop: "30px",
            }}
          >
            {[
              {
                title: "🚀 Skills That Matter",
                description:
                  "Master in-demand skills like Web Development, Programming, and DSA.",
              },
              {
                title: "📅 Learn Your Way",
                description:
                  "Choose live classes for interactivity or learn at your own pace with recorded sessions.",
              },
              {
                title: "📚 Comprehensive Resources",
                description:
                  "Access curated content, hands-on projects, and industry insights.",
              },
            ].map((offer, index) => (
              <div
                key={index}
                style={{
                  background: "white",
                  padding: "25px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-10px)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 25px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 10px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h3
                  style={{
                    fontSize: "1.5rem",
                    color: "#4facfe",
                    marginBottom: "10px",
                  }}
                >
                  {offer.title}
                </h3>
                <p style={{ fontSize: "1rem", color: "#555" }}>
                  {offer.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies Section */}
      <section
        style={{
          maxWidth: "1200px",
          margin: "40px auto",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <h2 style={{ fontSize: "2.8rem", color: "#4facfe" }}>
          Technologies We Teach
        </h2>
        <p style={{ fontSize: "1.2rem", margin: "20px 0", color: "#555" }}>
          Learn the latest technologies to enhance your career prospects.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          {["HTML", "CSS", "JavaScript", "React"].map((tech, index) => (
            <div
              key={index}
              style={{
                padding: "10px 20px",
                borderRadius: "20px",
                backgroundColor: "#eef7ff",
                color: "#4facfe",
                fontWeight: "500",
                fontSize: "1rem",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              {tech}
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <footer
        style={{
          backgroundColor: "#4facfe",
          color: "#fff",
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontSize: "2.8rem", marginBottom: "10px" }}>
          Start Your Journey
        </h2>
        <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
          Join our community and unlock your potential!
        </p>
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
          }}
        >
          <button
            style={{
              backgroundColor: "#fff",
              color: "#4facfe",
              border: "none",
              padding: "15px 30px",
              fontSize: "1.2rem",
              borderRadius: "30px",
              cursor: "pointer",
              transition: "transform 0.3s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Explore Courses
          </button>
        </Link>
      </footer>
    </div>
  );
};

export default AboutUs;
