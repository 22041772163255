import React from "react";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer
        className="bg-body-tertiary text-center"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <MDBContainer className="p-4 pb-0">
          <section className="mb-4" style={{ margin: "0 auto" }}>
            {/* Twitter */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://x.com/Console2Success?t=w8Ajav2UlNO4PMoPSVpGuw&s=08"
              role="button"
              style={{ backgroundColor: "#55acee" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="twitter" />
            </MDBBtn>

            {/* Instagram */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://www.instagram.com/console2success?igsh=eTNkaDd6dHJkdTAw"
              role="button"
              style={{ backgroundColor: "#ac2bac" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="instagram" />
            </MDBBtn>

            {/* Linkedin */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://www.linkedin.com/company/console2success/"
              role="button"
              style={{ backgroundColor: "#0082ca" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="linkedin-in" />
            </MDBBtn>

            {/* Github */}
            <MDBBtn
              tag="a"
              floating
              size="lg"
              className="text-white m-1"
              href="https://youtube.com/@console2success?si=Gz4VqzI5keA-Q9ac"
              role="button"
              style={{ backgroundColor: "red" }}
              rippleColor="dark"
            >
              <MDBIcon fab icon="youtube" />
            </MDBBtn>
          </section>
        </MDBContainer>

        <div
          className="bg-body-tertiary text-center"
          // style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          <strong>
            For any support, please mail to console2success@gmail.com. Our team
            will get back to you within 24 hours.
          </strong>
        </div>
        <div
          className="text-center p-3"
          // style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2024 Console2Success.All Rights are Reserved{" "}
          <NavLink style={{ marginLeft: "25px" }} to="/privacy-policy">
            Privacy Policy
          </NavLink>
          <NavLink style={{ marginLeft: "25px" }} to="/terms-conditions">
            Terms and Conditions
          </NavLink>
          <NavLink style={{ marginLeft: "25px" }} to="/refund-policy">
            Refund/Cancellation
          </NavLink>
        </div>
        {/* Copyright */}
      </footer>
    </>
  );
};

export default Footer;
