import React from "react";
import { useParams, Link } from "react-router-dom";
import blogs from "./blog.json";

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs[id];

  if (!blog) {
    return <p>Blog not found!</p>;
  }

  // Container styles
  const containerStyle = {
    padding: "30px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    fontFamily: "Arial, sans-serif",
    background: "linear-gradient(145deg, #f0f4f8, #e6eff1)", // Soft gradient background
  };

  // Title styles
  const titleStyle = {
    fontSize: "2rem",
    fontWeight: "600",
    marginBottom: "10px",
    color: "#3f51b5", // Blue color for the title
    textAlign: "center",
  };

  // Paragraph styles
  const paragraphStyle = {
    fontSize: "1.1rem",
    lineHeight: "1.7",
    color: "#555",
    marginBottom: "15px",
  };

  // List styles
  const listStyle = {
    listStyleType: "circle",
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#333",
  };

  // Button styles
  const buttonStyle = {
    padding: "5px 20px",
    fontSize: "1rem",
    backgroundColor: "#ff4081", // Pink accent color
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
    display: "inline-block",
    textAlign: "center",
    transition: "background-color 0.3s, transform 0.2s",
  };

  // Button hover styles
  const buttonHoverStyle = {
    backgroundColor: "#e91e63", // Darker pink on hover
    transform: "scale(1.05)",
  };

  // Responsive styles
  const responsiveStyles = {
    "@media (max-width: 768px)": {
      containerStyle: {
        padding: "20px",
        maxWidth: "85%",
      },
      titleStyle: {
        fontSize: "2rem",
      },
      paragraphStyle: {
        fontSize: "1rem",
      },
      buttonStyle: {
        fontSize: "1rem",
        padding: "10px 20px",
      },
    },
    "@media (max-width: 480px)": {
      containerStyle: {
        padding: "15px",
        maxWidth: "100%",
      },
      titleStyle: {
        fontSize: "1.5rem",
      },
      paragraphStyle: {
        fontSize: "0.9rem",
      },
      buttonStyle: {
        fontSize: "0.9rem",
        padding: "8px 18px",
      },
    },
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>{blog.title}</h1>
      <p style={paragraphStyle}>
        <strong>Author:</strong> {blog.author}
      </p>
      <p style={paragraphStyle}>
        <strong>Category:</strong> {blog.category}
      </p>
      <p style={paragraphStyle}>
        <strong>Date:</strong> {blog.date}
      </p>
      <h3
        style={{
          ...titleStyle,
          fontSize: "2rem",
          marginTop: "30px",
          color: "#ff4081",
        }}
      >
        Highlights
      </h3>
      <ul style={listStyle}>
        {blog.main_content_highlights.map((highlight, index) => (
          <li key={index} style={paragraphStyle}>
            {highlight}
          </li>
        ))}
      </ul>
      <Link to="/blog" style={{ textDecoration: "none" }}>
        <button
          style={buttonStyle}
          onMouseEnter={(e) =>
            (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)
          }
          onMouseLeave={(e) =>
            (e.target.style.backgroundColor = buttonStyle.backgroundColor)
          }
        >
          Back to Blogs
        </button>
      </Link>
    </div>
  );
};

export default BlogDetail;
