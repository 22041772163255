import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import tutorials from "./htmlTutorials.json"; // Adjust the path as necessary
import "./HtmlPage.css";

function HtmlPage() {
  const { topic } = useParams();
  const navigate = useNavigate();

  const selectedTutorial =
    tutorials.find((tutorial) => tutorial.title === topic) || tutorials[0];

  return (
    <div className="html-page">
      {/* Sidebar */}
      <div className="sidebar">
        <h3>Topics</h3>
        <ul>
          {tutorials.map((tutorial, index) => (
            <li
              key={index}
              onClick={() => navigate(`/notes/html/${tutorial.title}`)}
              className={
                selectedTutorial.title === tutorial.title ? "active" : ""
              }
            >
              {tutorial.displayTitle}
            </li>
          ))}
        </ul>
      </div>

      {/* Content Area */}
      <div className="content-area">
        <h2>{selectedTutorial.displayTitle}</h2>
        {selectedTutorial.content.map((section, index) => (
          <div key={index}>
            <h3>{section.heading}</h3>
            {section.paragraphs.map((para, idx) => (
              <p
                key={idx}
                dangerouslySetInnerHTML={{
                  __html: para,
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HtmlPage;
